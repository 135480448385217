import React, { useState, useContext, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './BetPlayersHouse.css';
//shows up on bet detail page

const BetPlayersHouse = ({wager, canwager}) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [noAnchor, setNoAnchor] = useState(null);
  const [yesAnchor, setYesAnchor] = useState(null);

  const [yesCount, setYesCount] = useState(0);
  const [noCount, setNoCount] = useState(0);
  const [yesYou, setYesYou] = useState("");
  const [noYou, setNoYou] = useState("");
  const [yesClass, setYesClass] = useState("yesNormal");
  const [yesPopup, setYesPopup] = useState(["No Players"]);
  const [noPopup, setNoPopup] = useState(["No Players"]);
  const [noClass, setNoClass] = useState("noNormal");
  const [allowWager, setAllowWager] = useState(canwager);

  useEffect(() => {
    // check if we are within 2 hours of start time
    const timeToStart = wager.event.eventStartDate - Date.now();
    if(timeToStart<(2 * 60 * 60 * 1000)){
      setAllowWager(false);
    }

    if(wager.yesplayers!==undefined){
      if(wager.yesplayers.length>0){
        const popupText = wager.yesplayers.map(player => player.username).join(",");
        setYesPopup(popupText.split(','))
      }
      console.log(`wager.yesplayers: ${wager.yesplayers}`);
      setYesCount(wager.yesplayers.length);
      const you = wager.yesplayers.filter(player => player._id===auth.userId);
      if(you.length>0){
        setYesYou("You and ")
        setAllowWager(false);
        setYesCount(wager.yesplayers.length-1);
      }
    } else {
      wager.yesplayers = [];
    }
    
    if(wager.noplayers!==undefined){
      console.log(`wager.noplayers: ${wager.noplayers}`);
      if(wager.noplayers.length>0){
        const popupText = wager.noplayers.map(player => player.username).join(",");
        setNoPopup(popupText.split(','))
      }
      setNoCount(wager.noplayers.length);
      const you = wager.noplayers.filter(player => player._id===auth.userId);
      if(you.length>0){
        setNoYou("You and ")
        setAllowWager(false);
        setNoCount(wager.noplayers.length-1);
      } else {
        wager.noplayers = [];
      }
      
    }

    if(wager.arbitrated){
      setAllowWager(false);
      if(wager.arbitrationposition==="YES"){
        setYesClass("yesArbitrated");
      } else {
        setNoClass("noArbitrated");
      }
    }
    
  }, [wager, auth.userId])

  const noPopoverOpen = event => {
    setNoAnchor(event.currentTarget);
  };
  const noPopoverClose = () => {
    setNoAnchor(null);
  };
  const yesPopoverOpen = event => {
    setYesAnchor(event.currentTarget);
  };
  const yesPopoverClose = () => {
    setYesAnchor(null);
  };

  const voteYes = async () => {
    await registerVote("yes");
  }
  const voteNo = async () => {
    await registerVote("no");
  }

  /** 
     * @async
     * @param {string} vote - The vote to register. "yes" or "no".
     * @returns {Promise<void>}
     */
    const registerVote = async (vote) => {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_HOST}/bets/vote`,
          'POST',
          JSON.stringify({
            betid: wager._id,
            vote: vote,
            player: auth.userId
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if(vote==="yes"){
          wager.yesplayers.push(auth.userId);
        } else {
          wager.noplayers.push(auth.userId);
        }
        setAllowWager(false);
      } catch (err) {
        alert(`Failed to vote: ${err.message}` );
      }
    };
  

    const noOpen = Boolean(noAnchor);
    const yesOpen = Boolean(yesAnchor);

    return (
      <React.Fragment>
          <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <div className="yes-no-block">
              <Typography  variant="h6" 
               aria-owns={yesOpen ? 'yes-popover' : undefined}
               aria-haspopup="true"
               onMouseEnter={yesPopoverOpen}
               onMouseLeave={yesPopoverClose}
              >
                <div align="center" class={yesClass}>           
                  <b>{yesYou}{yesCount} players</b> <br/>
                  {allowWager && (
                    <Button sx={{ color: 'white', backgroundColor: 'green' }} onClick={voteYes} variant="contained">Vote Yes</Button>
                  )}
                </div>
              </Typography>
              <Popover
                id="yes-popover"
                sx={{ pointerEvents: 'none' }}
                open={yesOpen}
                anchorEl={yesAnchor}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={yesPopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1 }}>
                  {yesPopup.map((line, index) => (
                    <React.Fragment key={index}>
                    {line}
                    <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </Popover>

              <Typography variant="h4">
                <div class="yes-no">
                 <div class={yesClass} float="left"> <b>&nbsp;YES</b>&nbsp;&nbsp;</div>
                 <div class={noClass} float="right"><b> NO&nbsp;</b> </div>
                </div>
              </Typography>
              
              <Typography variant="h6" 
               aria-owns={noOpen ? 'no-popover' : undefined}
               aria-haspopup="true"
               onMouseEnter={noPopoverOpen}
               onMouseLeave={noPopoverClose}
               >
                <div align="center" class={noClass}>
                  <b>{noYou}{noCount} players</b> <br/>
                  {allowWager && (
                    <Button sx={{ color: 'white', backgroundColor: 'red' }} onClick={voteNo} variant="contained">Vote No</Button>
                  )}
                </div>
              </Typography>
              <Popover
                id="no-popover"
                sx={{ pointerEvents: 'none' }}
                open={noOpen}
                anchorEl={noAnchor}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={noPopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1 }}>
                  {noPopup.map((line, index) => (
                    <React.Fragment key={index}>
                    {line}
                    <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </Popover>
            </div>
           
          </Grid>
        </Grid>
     </React.Fragment>
    );
  

  }


export default BetPlayersHouse;
